@font-face {
    font-family: SFUIDisplay-Black;
    src: url("../fonts/SF-UI-Display-Black.otf") format("opentype");
}
@font-face {
    font-family: SFUIDisplay-Light;
    src: url("../fonts/SF-UI-Display-Light.otf") format("opentype");
}
@font-face {
    font-family: SFUIDisplay-Medium;
    src: url("../fonts/SF-UI-Display-Medium.otf") format("opentype");
}
@font-face {
    font-family: SFUIDisplay-Regular;
    src: url("../fonts/SF-UI-Display-Regular.otf") format("opentype");
}
body{
    font-family: SFUIDisplay-Medium;
}
/*login page*/
.login_page{
    display: flex;
    height: 100vh;
}
.login_page .right_login{
    background: url("../img/uxui.png") no-repeat;
    background-size: cover;
    width: 50%;
}
.login_page .left_login{
    width: calc(50% + 1px);
    background-color: #fff;
    background: url(../img/top_left.png) left top no-repeat, url(../img/top_right.png) right top no-repeat,  url(../img/bottom_right.png) right bottom no-repeat, url(../img/bottom_left.png) left bottom no-repeat;
    background-repeat: no-repeat;
    margin-right: -1px;
}
.login_form{
    display: flex;
    justify-content: center;
}
.login_container{
    width: 414px;
    margin-top: 41px;
    position: relative;
}
.login_container .title1{
    font-size: 34px;
    font-family: SFUIDisplay-Black;
    font-weight: 900;
    margin-bottom:2px;
    margin-top: 25px;
    color: #474747;
}
.login_container .title2{
    font-size: 21px;
    font-family: SFUIDisplay-Medium;
    font-weight: 500;
    margin-bottom:30px;
    color: #474747;
}
.login_container label{
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 19px;
    font-size: 14px;
    color: #4b4b4b;
    font-family: SFUIDisplay-Regular;
    width: 100%;
    margin: 0;
}
form input::placeholder, input:-ms-input-placeholder {
    font-family: SFUIDisplay-Light;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    opacity: 1; /* Firefox */
    color: #9e9e9e;
}
input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: SFUIDisplay-Light;
}
input::-moz-placeholder { /* Firefox 19+ */
    font-family: SFUIDisplay-Light;
}
input:-ms-input-placeholder { /* IE 10+ */
    font-family: SFUIDisplay-Light;
}
input:-moz-placeholder { /* Firefox 18- */
    font-family: SFUIDisplay-Light;
}
.login_container label .label_txt{
    padding-bottom: 8px;
}
.login_container label input{
    padding: 13px 20px;
    width: 100%;
    border-radius: 5px;
    border: solid 1px #38abe9;
    background-color: #ffffff;
    outline: none;
    color: #4b4b4b;
    font-family: SFUIDisplay-Regular;
}
.but{
    height: 50px;
    width: 100%;
    border-radius: 5px;
    background-color: #55b8ed;
    outline: none;
    border: none;
    font-family: SFUIDisplay-Light;
    font-size: 14px;
    color: #ffffff;
    text-transform: uppercase;
    margin-top:7px;
}
/*dashboard*/
.navigation{
    width: 226px;
    height: 100vh;
    background-color: #2c2c2c;
    position: fixed;
    padding: 0 15px;
    padding-top: 80px;
    transform:  translateX(-100%);
    transition: all .5s;
    overflow: auto;
    padding-bottom: 25px;
}
.navigation.open_navigation{
    transform:  translateX(0);
}
.navigation .head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 19px;
    font-family: SFUIDisplay-Light;
    margin-bottom: 20px;
}
.navigation input.addfolder, .navigation input#invitePeople{
    margin-left: 20px;
}
.navigation .new_folder{
    cursor: pointer;
}
.navigation input{
    border: none;
    background: transparent;
    font-size: 12px;
    color: #fff;
    width: 100%;
    padding: 5px;
    outline: none;
}
.navigation input::placeholder {
    font-family: SFUIDisplay-Medium;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #505050;
}
.navigation ul{
    padding: 0;
    margin: 0;
    list-style: none;
}
.navigation ul .link_nav{
    color: #848484;
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;
}
.navigation .group_item{
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-left: 25px;
}
.navigation .group_item.edit_folder .group1,.navigation .group_item.edit_folder .delete{
    display: none;
}

.navigation .group_item .group1{
    display: flex;
    align-items: center;
}
.navigation .group_item .edit {
    padding-left: 15px;
    cursor: pointer;
}
.navigation .delete {
    cursor: pointer;
    top: -2px;
}
.navigation .nav2{
    margin-top: 70px;
}
.navigation .nav2 .invite{
    font-size: 7px;
    cursor: pointer;
}
.main_dashboard{
    transition: all .5s;
    padding-top: 151px;

}
.main_dashboard.open_navigation_dashboard{
    margin-left: 226px;
}
.main_dashboard.open_navigation_dashboard .head, .main_dashboard.open_navigation_dashboard .search_container{
    left: 226px;
    width: calc(100% - 226px);
}
.main_dashboard .search_container{
    transition: all .5s;
}
.main_dashboard .head{
    display: flex;
    justify-content: space-between;
    padding: 16px 45px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 1;
    transition: all .5s;
    z-index: 3;
}
.main_dashboard .logo_container{
    display: flex;
    align-items: center;
    text-decoration: none;
}
.main_dashboard .logo_container span{
    margin-left: 10px;
    font-size: 16px;
    font-weight: 300;
    color: #02a8d2;
}
.main_dashboard .right{
    display: flex;
    align-items: center;
}
.main_dashboard .right a{
    font-size: 11px;
    font-weight: 300;
    color: #02a8d2;
    text-decoration: none;
    margin-right: 10px;
}
.main_dashboard .right a:hover,.main_dashboard .right .logout:hover{
    text-decoration: underline;
}
.main_dashboard .right .logout{
    margin-top: 10px;
}
.main_dashboard .right .rt{
    font-size: 11px;
    font-weight: 300;
    color: #02a8d2;
    text-decoration: none;
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #55b8ed;
    margin-left: 30px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
}
.main_dashboard .right .rt .menu_bot {
    background: #fff;
    position: absolute;
    right: 0;
    top: 35px;
    border: 1px solid #e8e2e2;
    border-radius: 5px;
    padding: 10px;
    color: #02a8d2;
    display: none;
    min-width: 62px;
}
.men_but_prof{
    width: 100%;
    height:100%;
}
.main_dashboard .search_container{
    display: flex;
    justify-content: space-between;
    padding: 16px 45px;
    position: fixed;
    top: 71px;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 2;
}
.main_dashboard .search_container .left{
    display: flex;
    align-items: center;
}
.main_dashboard .search_container .menu_but{
    cursor: pointer;
    margin-right: 10px;
}
.main_dashboard .search_container .categ_name{
    font-size: 21px;
    font-weight: 500;
    color: #474747;
    line-height: 41px;
}
.main_dashboard .search_container .categ_name .edit_proj.edit{
    display: none;
}
.main_dashboard .search_container .categ_name input{
    line-height: 0;
    border: none;
    outline: none;
}
.main_dashboard .search_container .categ_name input:disabled{
    background: transparent;
}
.main_dashboard .search_container .right{
    display: flex;
}
.main_dashboard .search_container .right label{
    margin: 0;
}
.main_dashboard .search_container .right input{
    width: 428px;
    height: 40px;
    object-fit: contain;
    border-radius: 22px;
    border: solid 1px #bebebe;
    outline: none;
    padding: 0 16px;
}
.main_dashboard .search_container .right input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: SFUIDisplay-Light;
    font-size: 17px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    opacity: 1;
    color: #afafaf;
}
.main_dashboard .search_container .right input::-moz-placeholder { /* Firefox 19+ */
    font-family: SFUIDisplay-Light;
    font-size: 17px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    opacity: 1;
    color: #afafaf;
}
.main_dashboard .search_container .right input:-ms-input-placeholder { /* IE 10+ */
    font-family: SFUIDisplay-Light;
    font-size: 17px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    opacity: 1;
    color: #afafaf;
}
.main_dashboard .search_container .right input:-moz-placeholder { /* Firefox 18- */
    font-family: SFUIDisplay-Light;
    font-size: 17px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    opacity: 1;
    color: #afafaf;
}
.main_dashboard .search_container .right input{
    color: #474747;
}
.new_proj{
    height: 40px;
    width: 116px;
    border-radius: 5px;
    background-color: #55b8ed;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-family: SFUIDisplay-Light;
    margin-left: 12px;
}
.main_dashboard .projects_container{
    display: flex;
    flex-wrap: wrap;
    padding: 25px 15px;
}
.main_dashboard .proj_cont1{
    background-color: rgba(85, 184, 237, 0.11);
    min-height: calc(100vh - 151px);
    background-image: url('../img/gr1.svg');
    background-attachment: fixed;
    background-position: left calc(100% + 150px);
    background-repeat: no-repeat;
    background-size: 100%;
}
.empt_proj{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 226px);
}
.empt_proj .new_proj{
    margin-top: 80px;
}
.empt_proj .drag_txt{
    color: #ccc;
    font-size: 22px;
    margin-top: 80px;
}
.main_dashboard .projects_container .proj{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px;
}
.main_dashboard .projects_container .proj_img{
    width: 100%;
    /*height:188px;*/
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
}
.main_dashboard .projects_container .proj_bot{
    width: 286px;
    height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 12px;
    border: solid 1px #e7e7e7;
    font-size: 16px;
    z-index: 1;
}
.proj_top{
    position: relative;
    height: 188px;
    width: 215px;
    overflow: hidden;
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 0 7px 5px rgba(0, 0, 0, .1);
}
.proj_top .over_link{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.over_link a{
    text-decoration: none;
    color: #fff;
    padding: 5px 10px;
    border: 1px solid #fff;
    border-radius: 5px;
    z-index: 1;
    transition: all .2s;
}
.over_link a:hover {
    background-color: #55b8ed;
    border: 1px solid #55b8ed;
}
a.prev_link {
    position: absolute;
    right: 35px;
    top: 5px;
    z-index: 1;
}
.main_dashboard .projects_container .proj_bot .name{
    color: #656565;
    text-align: center;
}
.main_dashboard .projects_container .proj_bot .date{
    color: #aeaaaa;
    padding-top: 7px;
    font-size: 13px;
    display: flex;
    flex-direction: column;
}
.over_proj {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .5s;
}
.over_link{
    width: 100%;
    height:100%;
    background-color: rgba(0, 0, 0, 0.45);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    max-height: 400px;
}
.over_link2{
    position: absolute;
    right: 35px;
    top: 5px;
    z-index:2;
}
.proj_top:hover .over_proj{
    opacity: 1;
}
.delete{
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
    cursor: pointer;
}
.main_dashboard .projects_container .over_proj .folder{
    position: absolute;
    left: 5px;
    top: 5px;
    z-index: 1;
}
.folders_select{
    padding: 10px 12px;
    background: #fff;
    border-radius: 5px;
    display: none;
    box-shadow: 0 0 5px 0px #000;
}
form.folders_select.animated.bounceOut{
    display: none;
}
.main_dashboard .projects_container .over_proj .fold_action{
    cursor: pointer;
}
label.radio{
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-size: 14px;
}
label.radio input{
    display: none;
}
label.radio .icon_radio{
    width: 18px;
    height: 18px;
    background:url('../img/circle-outline.svg') no-repeat center;
    background-size: 18px;
    display: block;
    position: relative;
    margin-right:10px;
}
label.radio input:checked + .icon_radio{
    background:url('../img/checked.svg') no-repeat center;
    background-size: 18px;
}
.folders_select label.radio:not(:last-child){
    margin-bottom: 7px;
}
.folders_select.bounceIn, .folders_select.bounceOut{
    display: block;
}
.folders_select.animated {
    animation-duration: 0.3s;
    animation-delay: 0s;
}
.file_inp{
    display: none;
}
.new_proj{
    cursor: pointer;
}
.main_dashboard .projects_container.one_proj{
    padding: 25px 66px;
}
.one_proj .item{
    margin: 0 15px;
    margin-bottom: 50px;
    padding: 3px;
}
.one_proj .item .item_container{
    max-width: 215px;
    max-height: 380px;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 7px 3px rgba(0, 0, 0, .1);
}
.one_proj .item img{
    max-width: 100%;
}
.one_proj .item .item_container > img{
    border-radius: 5px;

    min-width: 200px;
}
.one_proj .item_container{
    position: relative;
}
.count_img {
    padding: 0 84px;
    padding-top: 15px;
}
.count_img span{
    line-height: 22px;
    border-radius: 10px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
    color: #8492a6;
    box-shadow: inset 0 0 0 1px #d3dce6;
    display: inline-block;
}
.one_proj .info_item{
    font-size: 10px;
    font-weight: 500;
    color: #aeaaaa;
    padding-bottom: 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.one_proj .item_container:hover .over_proj{
    opacity: 1;
}
.one_proj .item_container .over_proj .over_link{
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
}
.drag_handl{
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
}
.drag_handl img{
    margin-left: 5px;
    margin-top: 5px;
}
.prototype{
    padding-top: 41px;
}
.prototype .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    background-color: #031838;
    height: 41px;
    z-index: 11;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
}
.prototype .header .left .back{
    cursor: pointer;
    font-size: 11px;
    font-weight: 500;
    color: #ffffff;
    text-decoration: none;
}
.prototype .header .left .back span{
    padding-right: 10px;
}
.prototype .header .mid{
    display: flex;
}
.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.prototype .header .proto_but{
    width: 82px;
    height:28px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #232d3c;
    color: #fff;
    font-size: 11px;
    margin: 0 3px;
    z-index:2;
    text-decoration: none;
}
.prototype .header .proto_but.active{
    background-color: #55b8ed;
}
.prototype .header .proto_but.prev-color{
    background-color: #55ed75;
}
.prototype .header .proto_but.done_color{
    background-color: #55b8ed;
}
.prototype .header .right{
    display: flex;
}
.prototype .header .right input{
    display: none;
}
.prototype .header label{
    margin: 0;
}
.main_prototype{
    background-color: #202c3f;
    width: 100%;
    min-height: calc(100vh - 41px);
    padding-bottom: 100px;
    padding-top: 76px;
    align-items: initial;
}
.main_prototype .prev, .main_prototype .next{
    width: 355px;
    height: 355px;
    background-color: rgba(216, 216, 216, 0.08);
    position: fixed;
    top:50%;
    transform: translate(-50%, -50%);
    left: 0;
    border-radius: 50%;
    cursor: pointer;
    transition: all .3s
}
.main_prototype .prev.left_px{
    left: 430px;
    z-index: 1;
}
.main_prototype .next{
    transform: translate(50%, -50%);
    left: auto;
    right: 0;
}
.main_prototype .prev:hover, .main_prototype .next:hover{
    background-color: rgba(251, 251, 251, 0.08);
}
.itemdrag{
    border: solid 1px #0985ff;
    background-color: rgba(18, 166, 231, 0.51);
    height: 100%;
    width: 100%;
    position: relative;
}
.img-container{
    margin: 0 auto;
}
.img-container, .img-container1{
    position: relative;
}
.screen_cont_inside {
    position: relative;
   /*padding-bottom: 302px;*/
}
.del_drag{
    cursor: pointer;
    width: 15px;
    height: 15px;
    background-color: #2db22b;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    right: -8px;
    color: #fff;
    font-size: 11px;
    border-radius: 50%;
    z-index: 1;
    display: none;
}
.itemdrag{
    z-index: 1;
}
.img_name_overlow{
    max-width: calc(100% - 40px);
    overflow: hidden;
    text-overflow: ellipsis;
}
.itemdrag:hover .del_drag{
    display: flex;
}

.main_prototype  .bot_container{
    width: 100%;
    height: 373px;
    border-top: 2px solid #55b8ed;
    background-color: #162031;
    position: fixed;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 42px;
    display: none;
    z-index: 10;
}
.main_prototype  .bot_container .d-flex{
    overflow: auto;
    height: 100%;
    align-items: flex-start;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
}
.main_prototype  .bot_container .current_img,.main_prototype  .bot_container .only_img{
    margin: 0 24px;
    position: relative;
    border: 7px solid transparent;
    padding-bottom: 13px;
}
.main_prototype .bot_container .current_img img{
    height: 277px;
}
.main_prototype  .bot_container .only_img img{
    height: 277px;
}
.main_prototype  .bot_container .current_img{
    margin: 0;
    padding: 0 24px;
}
.main_prototype  .bot_container .current_img:after{
    content: '';
    height: 322px;
    border-right: 1px solid #758bb1;
    position: absolute;
    right: 0;
    top: -21px;
}
.select_txt{
    display: none;
    justify-content: space-between;
    font-size: 9px;
    font-weight: 500;
    color: #ffffff;
    background: #2db22b;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.selected .select_txt{
    display: flex;
}
.main_prototype  .bot_container .only_img.selected{
    border-radius: 8px;
    border: 7px solid #2db22b;
}
.main_prototype .close_thumb {
    width: 82px;
    height: 28px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #232d3c;
    color: #fff;
    font-size: 11px;
    margin: 0 3px;
    text-decoration: none;
}
.main_prototype  .bot_container .close_thumb{
    position: absolute;
    right: 15px;
    bottom: 332px;
}
.img-container .react-draggable > span > div:nth-child(6){
    width: 15px!important;
    height: 15px!important;
    right: 0px!important;
    bottom: 0px!important;
    border-right: 3px solid rgba(18, 166, 231, 0.51)!important;
    border-bottom: 3px solid rgba(18, 166, 231, 0.51)!important;
    z-index: 2;
}
.img-container1 .onComment, .comments_container .bind_comment{
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);
    background-color: #e0de25;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 1;
}
.comments_container .bind_comment{
    position: absolute;
    right: -15px;
    top:-15px;
    width: 40px;
    height: 40px;
    color: #000;
}
.comments_container{
    width: 100%;
    height: 100%;
    border-right: 1px solid #3b8fa6;
    background-color: #162031;
    /*position: fixed;*/
    /*left: 0;*/
    /*top:0;*/
    z-index: 2;
    transition: all .3s;
}
.comments_container .top{
    font-size: 16px;
    font-weight: 500;
    color: #aeaaaa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    padding: 15px 20px;
}
.comments_container .comment .comment_incl{
    display: flex;
    margin-bottom: 15px;
}
.comments_container .comment .comment{
    margin-left: 56px;
}
.comments_container .comment .left{
    width: 41px;
    height: 41px;
    background-color: #55b8ed;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    margin-right: 15px;
}
.comments_container .right{
    width: calc(100% - 56px);
}
.dragLeft.react-draggable {
    position: fixed!important;
    left: 0;
    transform: translate(0)!important;
    height: calc(100vh - 41px)!important;
    top: 41px!important;
    z-index: 1;
}
.comment_list{
    height: calc(100vh - 213px);
    overflow: auto;
    padding: 15px 20px;
}
.comments_container .comment .main_comment{
    background-color: #fff;
    border-radius: 9px;
    padding: 19px;
    padding-top: 13px;
    padding-bottom: 23px;
    color: #737373;
    position: relative;
}
.comments_container .comment .name_sender{
    font-size: 11px;
    margin-bottom: 18px;
}
.comments_container .comment .mess_txt{
    font-size: 14px;
    white-space: pre;
}
.comments_container .comment_bot,.comments_container .comment_action{
    display: flex;
    font-size: 11px;
    color: #fff;
}
.comments_container .comment_action.active .add_rep,.comments_container .comment_action .cancel_rep{
    display: none;
}
.comments_container .comment_action.active .cancel_rep{
    display: block;
}
.comments_container .comment_bot{
    justify-content: space-between;
    margin-top: 9px;
}
.comments_container .comment_action div{
    cursor: pointer;
}
.comments_container .comment_action .reply{
    margin-left: 15px;
}
.comments_container .form_container{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 20px;
}
.comments_container .form_container .send_top{
    position: relative;
    width: 100%;
}
.comments_container  textarea{
    background: #fff;
    width: 100%;
    height: 45px;
    border-radius: 7px;
    border: solid 1px #979797;
    resize: none;
    outline: none;
    padding-left: 19px;
    padding-right: 105px;
    padding-top: 13px;
    font-size: 13px;
}
.comments_container   textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: SFUIDisplay-Light;
    opacity: 1; /* Firefox */
    color: #9e9e9e;
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    /*line-height: normal;*/
    letter-spacing: normal;
    color: #828282;
}
.comments_container   textarea::-moz-placeholder { /* Firefox 19+ */
    font-family: SFUIDisplay-Light;
    opacity: 1; /* Firefox */
    color: #9e9e9e;
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    /*line-height: normal;*/
    letter-spacing: normal;
    color: #828282;
}
.comments_container   textarea:-ms-input-placeholder { /* IE 10+ */
    font-family: SFUIDisplay-Light;
    opacity: 1; /* Firefox */
    color: #9e9e9e;
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    /*line-height: normal;*/
    letter-spacing: normal;
    color: #828282;
}
.comments_container   textarea:-moz-placeholder { /* Firefox 18- */
    font-family: SFUIDisplay-Light;
    opacity: 1; /* Firefox */
    color: #9e9e9e;
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    /*line-height: normal;*/
    letter-spacing: normal;
    color: #828282;
}
.comments_container .form_container .send_top .close_thumb, form.reply_form .close_thumb{
    background-color: #55b8ed;
    outline: none;
    border: none;
    position: absolute;
    right: 9px;
    top: 9px;
}
.comments_container .form_container .anotation {
    font-size: 11px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
}
.comments_container .form_container .anotation.del{
    display: none;
    color: red;
}
.comments_container .form_container  .send_bot.added_anotation  .del{
    display: inline-block;
}
.comments_container .form_container  .send_bot.added_anotation  .add{
    display: none;
}
.main_img{
    position: relative;
    max-width: 1200px;
}
/*.main_img img{*/
    /*max-width: 1200px;*/
/*}*/
/*#comment_main_container .main_img{*/
    /*max-width: calc(100vw - 700px);*/
/*}*/
#comment_main_container .img-container1{
    margin-left: 469px;
    position: relative;
}
#comment_main_container .scr_name{
    margin-left: 500px;
}
.main_img.active:before{
    content: 'Click here to add a anotation.';
    position: absolute;
    background: rgba(0,0,0,0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #fff;
}
.comments_container form.reply_form {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
    position: relative;
}
.comments_container form.reply_form textarea{
    width: 350px;
}
/*#comment_main_container{*/
    /*z-index: -1;*/
/*}*/
/*popups*/
button.close_mod {
    background: transparent;
    border: navajowhite;
    outline: none;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    z-index: 5;
}
.modal-body{
    padding-top: 2rem;
}
.modal_bod form label{
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
}
.modal_bod form label input{
    width: 100%;
    height: 40px;
    object-fit: contain;
    border-radius: 5px;
    border: solid 1px #878787;
    outline: none;
    padding: 0 16px;
}
.modal_head{
    text-align: center;
    font-size: 24px;
    margin-bottom: 15px;
}
.modal_bod button.new_proj {
    outline: none;
    border: none;
    width: 100%;
    margin: 0;
}
.but_cont.two_but{
    display: flex;
}
.but_cont.two_but .cancel_pr{
    margin-left: 15px;
}
button.new_proj.cancel_pr {
    background: #a29f9f;
}
.itemdrag.selected_element {
    background-color: rgba(7, 103, 6, 0.8);
    border: solid 1px rgba(7, 103, 6, 0.8);
}
.error-mess {
    position: absolute;
    left: 0;
    top: 218px;
    color: red;
}
.loading_section{
    background-color: red;
    position: absolute;
    left: 0;
    top: 0;
    height: 3px;
}
.loading_section1{
    background-color: red;
    position: fixed;
    left: 0;
    top: 0;
    height: 3px;
    z-index:999;
}
.modal_bod .login_container{
    width: 100%;
    margin-top: 0;
}
.item img {
    max-width: 100%;
}
.proto_cont_but{
    width: 89px;
}
.comments_container .comment .completed .main_comment {
    background: #c0fec0;
}
.comments_container .completed .comment_action div.complete{
    display: none;
}
.comments_container  .comment_action div.complete1{
    display: none;
}
.comments_container .completed .comment_action div.complete1{
    display: block;
    color: #c0fec0;
}
.error-mess1 {
    color: red;
}
.api{
    margin-bottom: 13px;
    border-radius: 7px;
    border: solid 1px #979797;
    background-color: #ffffff;
    color: #5f5f5f;
}
.api .top_api{
    display: flex;
    padding: 11px 14px;
    justify-content: space-between;

}
.api .top_api .top_left_api, .api .top_api .top_right_api{
    display: flex;
    align-items: center;
}
.api .top_api .api_name{
    font-size: 18px;
    font-family: SFUIDisplay-Light;
}
.api .top_api .api_number{
    width: 40px;
    height: 40px;
    background-color: #e0de25;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    font-family: SFUIDisplay-Medium;
    margin-right: 15px;
    border-radius: 50%;
}
.api .edit_api{
    margin-right: 30px;
    color: #5f5f5f;
    font-size: 13px;
    font-family: SFUIDisplay-Light;
    cursor: pointer;
}
.api .edit_api.del_api{
    margin-right: 0;
}
.full_width{
    width: 100%;
}
.api .bot_api{
    padding: 11px 14px;
    font-size: 13px;
    font-family: SFUIDisplay-Light;
    color: #5f5f5f;
}
.api .bot_api .or{
    display: flex;
    align-items: center;
}
.api .bot_api .or:after{
    content: '';
    width: 50%;
    height: 1px;
    background: #d8d8d8;
    margin-left: 10px;
}
.api .bot_api .or:before{
    content: '';
    width: 50%;
    height: 1px;
    background: #d8d8d8;
    margin-right: 10px;
}
.basic-single1 >div, .basic-single >div{
    border-color: #d8d8d8;
    border-radius: 7px;
    min-height: 45px;
}
.basic-single1{
    width: 100px;
    font-size: 14px;
    font-family: SFUIDisplay-Black;
}
.basic-single1 .css-bgvzuu-indicatorSeparator{
    display: none;
}
.basic-single1 >div{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.api .col1 {
    display: flex;
    margin-top: 10px;
}
.api .col1 .inpUrl{
    padding: 2px 8px;
    width: calc(100% - 100px);
    outline: none;
    border: 1px solid #d8d8d8;
    border-left: none;
    font-size: 14px;
    color: #5f5f5f;
    font-family: SFUIDisplay-Light;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}
.api .col1 label{
    width: 100%;
}
.api .inpDesc{
    width: 100%;
    border: none;
    border-bottom: 1px solid #d8d8d8;
    outline: none;
}
.api .tab_head{
    display: flex;
}
.api .tab_head .tabItem{
    cursor: pointer;
    font-family: SFUIDisplay-Medium;
    text-transform: uppercase;
}
.api .tab_head .tabItem.active{
    border-bottom: 1px solid #e0de25;
}
.api .tab_head .tabItem:not(:last-child){
    margin-right: 10px;
}
.api .tab_body .head_row{
    display: flex;
}
.api table{
    width: 100%;
    margin-top: 15px;
}
.api table td{
    border-bottom: 1px solid #979797;
    border-left: 1px solid #979797;
    padding: 3px 5px;
    width: 33%;
}
.api table tr td:first-child, .api table tr td:nth-child(2){
    border-left: 0;
    min-width: 25px;
    width: 25px;
}
.drag{
    cursor: move;
}
.api table td input{
    border: none;
    outline: none;
    width: 100%;
    font-size: 11px;
}
.api table .del_icon{
    cursor: pointer;
}
.api .addrow {
    margin-top: 12px;
    padding: 3px;
    width: 80px;
    border: 1px solid #979797;
    cursor: pointer;
}
.api .response{
    margin-top: 15px;
    width: 100%;
}
.api .response textarea{
    width: 100%;
    height: auto;
    min-height: 100px;
    resize: vertical;
}
.valid_json {
    color: red;
    font-size: 10px;
    font-family: Arial;
    position: absolute;
    top: -18px;
    right: 23px;
    background: #fff;
    padding: 3px 5px;
    border-radius: 5px;
}
.valid_json:after {
    content: '';
    z-index: 5;
    position: absolute;
    bottom: -8px;
    right: 18px;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 8px 8px 0;
    border-top-color: #ffffff;
}
.api .save{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.api .save .save_but{
    background-color: #55b8ed;
    width: 82px;
    height: 28px;
    cursor: pointer;
    border-radius: 5px;
    color: #fff;
    font-size: 11px;
    margin: 0 3px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}
.api .tab_body.active{
    display: block;
}
.api .tab_body{
    display: none;
    position: relative;
}
.api .check_temp{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 0;
    margin-right: 10px;
}
.api .check_temp input{
    width: 15px;
    height: 15px;
    margin-left:10px;
}
.api .edit_api .close_span, .api .edit_api.active .edit_span{
    display: none;
}
.api .edit_api.active .close_span{
    display: block;
}
.itemdrag1{
    position: absolute;
    border: solid 1px #0985ff;
    background-color: rgba(18, 166, 231, 0.51);
    cursor: pointer;
    opacity: 0;
    transition: all .3s;
    z-index: 1;
}
.img-container.active .itemdrag1{
    opacity: 1;
}
.prototype .header .mid.mod_preview{
    position: fixed;
    bottom: 15px;
    right: 15px;
}
.prototype .header .mid.mod_preview .proto_but{
    background: #676767;
}
.prototype .header .mid.mod_preview .proto_but.active {
    background-color: #55b8ed;
}
.img-container1 .onComment.mod_preview{
    position: absolute;
    cursor: pointer;
}
.delete_com{
    padding-right: 15px;
}
.api .edit_api.prev_api{
    margin-right: 0;
}
.comment_list.mod_preview{
    height: calc(100vh - 109px);
}
.api_txt{
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5f5f5f;
    font-family: SFUIDisplay-Light;
    margin-top: 20px;
}
.bot_api .link_method{
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    border: solid 1px #ebebeb;
    background-color: #f4f4f4;
    height: 40px;
    align-items: center;
    padding: 0 10px;
}
.bot_api .link_method .method_api{
    padding-right: 30px;
    color: #278419;
    font-family: SFUIDisplay-Medium;
}
.bot_api  .right_meth{
    cursor: pointer;
    position: relative;
}
.bot_api .right_meth .tool{
    position: absolute;
    top: -28px;
    right: 3px;
    background: #000;
    padding: 5px;
    border-radius: 5px;
    color: #ffff;
    font-size: 10px;
}
.relative_block {
    position: relative;
}
.prev_table_head{
    color: #5f5f5f;
    border-bottom: 1px solid #979797;
    padding-bottom: 5px;
}
.api table.prev_table{
    margin-bottom: 15px;
}
.api table.prev_table td{
    border: none;
    font-size: 12px;
    color: #5f5f5f;
    padding-bottom: 10px;
    padding-top: 10px;
    vertical-align: top;
}
.api table.prev_table tr td:first-child{
    width: 30%;
    padding-left: 25px;
}
.api table.prev_table tr td:last-child{
    width: 70%;
}
.api table.prev_table tr td .api_desc{
    font-size: 9px;
    color: #999999;
}
pre{
    border: solid 1px #ebebeb;
    background-color: #f4f4f4;
    padding: 5px;
    color: #5f5f5f;
    font-size: 10px;
    margin-top: 10px;
    max-height: 200px;
}
pre code,
pre .line-number {
    font:normal normal 12px/14px "Courier New",Courier,Monospace;
    display:block;
}

pre .line-number {
    float:left;
    margin:0 1em 0 -1em;
    border-right:1px solid;
    text-align:right;
}

pre .line-number span {
    display:block;
    padding:0 .5em 0 1em;
}

pre .cl {
    display:block;
    clear:both;
}
.prev_head_tab{
    margin-top: 5px;
}
.bot_api .right_meth.copy_resp {
    position: absolute;
    top: 6px;
    right: 16px;
}
.bar_load{
    position: fixed;
    left: 100px;
    top: 100px;
    background: red;
    width: 100px;
    height: 100px;
    z-index: 99999999999;
}
.bold_api{
    font-family: SFUIDisplay-Medium!important;
}
.scr_name{
    padding-bottom: 5px;
    font-family: SFUIDisplay-Light;
    font-size: 12px;
    color: #aeaaaa
}
.send_bot {
    display: flex;
    justify-content: space-between;
}
.pagination li a{
    border: none;
    width: 42px;
    height: 42px;
    margin: 0 7px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 14px;
    color: #7C86A2;
    text-align: center;
    line-height: 25px;
}
.pagination li:first-child a,.pagination li:nth-child(2) a,.pagination li:last-child a, .pagination li:nth-last-child(2) a  {
    width: 35px;
    padding: 0.5rem 0;
    margin: 0;
    display: flex;
    justify-content: center;
}
.pagination li:first-child a:hover,.pagination li:nth-child(2) a:hover,.pagination li:last-child a:hover, .pagination li:nth-last-child(2) a:hover{
    background: transparent;
    color: #7C86A2;
}
.pagination li.active a {
    background: #55b8ed !important;
    border:none;
    box-shadow:none;
}
.f_link2:after{
    content: '';
    display: block;
    width: 23px;
    height: 23px;
    background: url(../img/active_right.svg) no-repeat center;
    transform: rotate(-180deg);
}
.b_link2:after{
    content: '';
    display: block;
    width: 23px;
    height: 23px;
    background: url(../img/active_right.svg) no-repeat center;
    transform: rotate(0);
}
.pagination li.disabled a {
    opacity: 0.4;
}
.pagination li a {
    position: relative;
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
}
.pagination li a:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}
.pagination li.active a{
    color: #fff;
}
.pagination .li.disabled a:hover{
    background: transparent !important;
    border:none;
    box-shadow:none;
}
.pagination li a.disabled {
    opacity: 0.4;
}
.pagination {
    display: -webkit-flex;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
    background: #ecf7fd;
    padding-bottom: 15px;
    margin-bottom: 0;
    justify-content: center;
}
.edit_inp_container {
    display: flex;
    width: 100%;
}
.edit_inp_container img{
    margin-right: 3px;
    cursor: pointer;
}
.header_proto {
    background: #a7a7a7;
    height: 1px;
}
.header_proto:before{
    content: 'Header';
    position: absolute;
    left: -67px;
    top: -12px;
    border-radius: 5px;
    background-color: #353d49;
    color: #fff;
    font-size:11px;
    padding: 5px;
    background-image: url(../img/up.svg) ;
    padding-left: 25px;
    background-position: 6px 7px;
    background-size: 10px;
    background-repeat: no-repeat;
}
.header_proto.footer_proto:before{
    content: 'Footer';
}
.screen_cont_inside {
    position: relative;
    /*padding-bottom: 302px;*/
}

.padding_bottom_400 {
    padding-bottom: 400px;
}

.drag_header {
    z-index: 9;
}
.main_img{
    max-width: inherit;
}
.zoom{
    position: fixed;
    right: 30px;
    top: 100px;
    color: #fff;
    z-index: 999;
    display: flex;
    align-items: center;
}
.zoomOut, .zoomIn {
    cursor: pointer;
    margin-right: 10px;
    width: 16px;
    height: 16px;
    background: #5f5f5f;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.zoomIn{
    margin-right: 0;
    margin-left:10px;
}
.zoom-preview {
    width: 160px;
    height: 160px;
    position: absolute;
    top: 50%;
    margin-top: -80px;
    left: 50%;
    margin-left: -80px;
    background-repeat: no-repeat;
    background-color: #fff;
    -webkit-box-shadow: inset 0 0 0 2px hsla(0, 0%, 100%, .8), 0 2px 6px 0 rgba(0, 0, 0, .4);
    box-shadow: inset 0 0 0 2px hsla(0, 0%, 100%, .8), 0 2px 6px 0 rgba(0, 0, 0, .4);
    border-radius: 100%;
    background-position: top;
    -webkit-transition: -webkit-transform .15s ease-in-out;
    transition: -webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out;
    transition: transform .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transform: scale(0);
    z-index: -1;
}
.header_px {
    color: #fff;
    position: absolute;
    top: -9px;
    font-size: 14px;
    right: 0;
    transform: translateX(calc(100% + 15px));
}
.device_container{
    position: relative;
}
.device_container.iphonex{
    width: 375px;
    height: 742px;
    background: url("../img/iphonex.png") no-repeat center;
    background-size: 100%;
    z-index: 2;
    padding: 50px 0;
}
.prev_img1{
    max-width: 100%;
}
.thumb-vertical{
    background-color: rgb(138, 138, 138);
    border-radius: 5px;
}
.img-container22{
    position: relative;
}
.view_prev{
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.center_cont{
    position: relative;
}
.header_prev {
    position: absolute;
    top: 50px;
    left: 0px;
    background-size: 100%;
    z-index: 1;
}
.footer_prev {
    position: absolute;
    bottom: 32px;
    left: 0px;
    background-size: 100%;
    z-index: 1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.onComment:after {
    width: 40px;
    height: 40px;
    content: '';
    display: block;
    background-color: #e0de25;
    position: absolute;
    z-index: -1;
    border-radius: 50%;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
}
.img-container1 .onComment{
    position: relative;
}
.scrollBar {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.loading_img{
    margin: 0 15px;
    margin-bottom: 50px;
    width: 215px;
    height: 200px;
    overflow: hidden;
    border-radius: 5px;
    padding: 3px;
    background: rgba(0,0,0,0.14);
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading_img img{
    width: 80px;
}
.visib-area{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background: rgba(0,0,0,0.5);
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: #fff;
}
.visib-area.active{
    display: flex;
}
body .alert {
    border: 1px solid transparent;
    z-index: 5;
    position: fixed;
    left: 0;
    top: 42px;
    width: 100%;
    padding: 5px 10px;
    border-radius: 0;
}
.icon_cont{
    background-color: #505050;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: all 0.3s;
}
.icon_cont:hover{
    background-color: #55b8ed;
}
